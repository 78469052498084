import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import BirthdayPartiesSlider from '../components/pages/BirthdayPartiesSlider';
import whyhostimg from '../images/whyhostimg.jpg';
import expectimg2 from '../images/expectimg2.jpg';
import LineFull from '../images/linefull.jpg';
import PriceLine from '../images/pricline.png';
import HireDJ from '../images/hiredjimg.jpg';
import HirePhoto from '../images/hirephotoimg.jpg';
import SetupImg from '../images/setupimg.jpg';
import WifiStar from '../images/wifistar.png';
import GreenLogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const BirthDay = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = data.wordpressPage.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={data.wordpressPage.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt" id="bookparty">
                <div className="container">
                    <h2 className="bluetxt">BOOK YOUR BIRTHDAY PARTY!</h2>

                    <div className="bookawrap">
                        <a
                            href={`tel:${post.acf.party_call_us_number}`}
                            className="wrtsbtn yellowbtn fullbtn"
                        >
                            CALL US TODAY: {post.acf.party_call_us_number}
                        </a>
                    </div>
                    <img src={LineFull} alt="linefull" />
                </div>
            </section>

            <section
                className="page-section respitesec notoppaddsec"
                id="toplftcarous"
            >
                <div className="container">
                    <div className="container flexwrap">
                        <div className="openplft">
                            <div
                                id="myCarousel3"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <BirthdayPartiesSlider
                                    galleryImages={post.acf.bp_gallery}
                                />
                            </div>
                        </div>
                        {/* <div className="whylistlft flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_why_image.localFile.childImageSharp
                                    .fluid.src
                            }
                            alt="why hosting"
                        />
                    </div> */}

                        <div className="whylistrgt flexbox toup">
                            <h2
                                className="bluetxt"
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.bp_why_title,
                                }}
                            />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.bp_why_reasons,
                                }}
                            />
                        </div>
                    </div>
                    {/* <div className="openplft">
                         <div
                            id="myCarousel3"
                            className="carousel slide display_none"
                            data-ride="carousel"
                        >
                            <BirthdayPartiesSlider
                                galleryImages={post.acf.bp_gallery}
                            />
                        </div>
						
                        <div
                            className="display_none"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_book_online_code_widget,
                            }}
                        /> 
                    </div>*/}

                    {/* <div className="openprgt">
                        <h2
                            className="bluetxt display_none"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_title,
                            }}
                        />
                        <div
                        className="display_none"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_content,
                            }}
                        /> 
                    </div>*/}
                </div>
            </section>

            <section
                className="whywelist page-section bg-primary text-white"
                id="openpbasic"
            >
                <div className="container middlewdt flexwrap">
                    <div className="flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_content,
                            }}
                        />
                    </div>

                    <div className="flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_what_to_expect_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="whitebordering"
                        />
                    </div>
                </div>
                {/* <div className="container flexwrap">
                    <div className="whylistlft flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_why_image.localFile.childImageSharp
                                    .fluid.src
                            }
                            alt="why hosting"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_why_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_why_reasons,
                            }}
                        />
                    </div>
                </div> */}
            </section>

            {/* <section
                className="page-section bg-secondary text-white openphours"
                id="pricingsec"
            >
                <div className="container middlewdt flexwrap">
                    <div className="flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_content,
                            }}
                        />
                    </div>

                    <div className="flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_what_to_expect_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="whitebordering"
                        />
                    </div>
                </div>
            </section> */}

            <section className="page-section centersec nopaddbottsec">
                <div className="container smallestwdt">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.bp_pricing_title,
                        }}
                    />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: post.acf.bp_pricing_description,
                        }}
                    />

                    <img className="linemar nomartopimg" src={LineFull} alt="linefull" />

                    <div className="packagesdiv">
                        {post.acf.bp_pricing_list.map((item, i) => (
                            <div
                                className="pricingbox bg-primary text-white"
                                key={i}
                            >
                                <h2>
                                    <span
                                        className="yellowtxt"
                                        dangerouslySetInnerHTML={{
                                            __html: item.bp_price_title,
                                        }}
                                    />
                                    <br />
                                    {item.bp_price_ammount}
                                </h2>
                                <img src={PriceLine} alt="priceline" />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.bp_price_package_content,
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    {/* <h3 class="bluetxt centerme">BIRTHDAY ADD-ONS</h3>
                    <div className='pricingtxt'><p>Call for more information!</p></div> */}
                    <div className="oplayhealwrap">
                    <h5 class="bluetxt">Book online</h5>
                   <div
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_book_online_code_widget,
                            }}
                        />  
                    </div>
                  
                         
                       
                </div>
            </section>
            <section className='new-featured-bp'>
                <div className="container smallestwdt">
                    <img className="linemar" src={LineFull} alt="linefull" />
                    <h2 className="bluetxt">Discover Our Spacious Party Room
</h2>
                    <p className="lastitem">
                        Celebrate in style in our 320 sq. ft. Party Room, perfectly equipped to host your special occasions. Whether you're throwing a birthday bash, a baby shower, or any other gathering, our room offers all the amenities you need for a successful event.
                    </p><br />
                    <p className="biggertxt lastitem"><strong>Room Features:</strong></p><br />
                    <p className="lastitem"><strong>- Full-Sized Fridge, Sink, and Dishwasher:</strong> Keep your drinks chilled, clean up easily, and enjoy the convenience of our fully equipped kitchenette.</p>
                    <p className="lastitem"><strong>- Multimedia Ready TV:</strong> Use our TV for your personalized photo montage, presentations, or to play your favorite shows and movies as part of our photo montage package or TV package.</p>
                    <p className="lastitem"><strong>- Flexible Seating Options:</strong> The room can be configured with standard-sized tables or small tables and chairs suitable for children aged 5 and under, accommodating your specific needs. We also have the option for food tables and a separate cake or gift table.</p>
                        <p className=""><strong>- Customizable Decor:</strong> You have the freedom to decorate the space in numerous styles to match your theme, making each event uniquely yours, or let us do it for you as part of our Platinum Package or Themed Décor pack. </p >
                    <p className="lastitem">
                        Whether you're planning a small, intimate gathering or a lively group celebration, our Party Room offers the flexibility and facilities to create memorable moments. Let us help you make your next event spectacular! 


                    </p>
                    <div className='img-bottom-bp'> <img src="https://werockthespectrumcary.wrtsfranchise.com/wp-content/uploads/2024/05/IMG_2940.jpg" alt="bp" /></div>
                    </div>
</section>
            <section className="page-section centersec notoppaddsec">
                <div className="container smallestwdt">
                    <img className="linemar" src={LineFull} alt="linefull" />


                    <div className="greenlft">
                        <img src={GreenLogo} alt="greenlogo" />
                    </div>

                    <div className="greenrgt">
                        <h2 className="bluetxt">We Use Only Green Products</h2>
                        <p className="biggertxt lastitem">
                            We believe in keeping our precious ones healthy and
                            safe. Our gym is cleaned daily at the end of the day
                            with only green products.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default BirthDay;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            acf {
                bp_book_online_code_widget
                bp_content
                bp_gallery {
                    id
                    localFile {
                        id
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_hire_a_dj_content
                bp_hire_a_dj_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_hire_a_dj_title
                bp_hire_photographer_content
                bp_hire_photographer_title
                bp_open_play_unlimited_link
                bp_pricing_description
                bp_pricing_title
                bp_private_playdates_title
                bp_private_playdates_content
                bp_set_open_air_content
                bp_title
                bp_set_open_air_title
                bp_what_to_expect_content
                bp_why_title
                bp_why_reasons
                bp_what_to_expect_title
                bp_hire_photographer_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_invitation_pdf {
                    url {
                        source_url
                    }
                }
                bp_pricing_list {
                    bp_price_ammount
                    bp_price_package_content
                    bp_price_title
                }
                bp_set_open_air_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_what_to_expect_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                party_call_us_number
                bp_why_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
